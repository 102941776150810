"use client";
/* eslint-disable @next/next/no-img-element */
import React, { memo, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./styles.css";
import API from "@/lib/Api";
import { BASE_API } from "@/config/site";
import Image from "next/image";
import Link from "next/link";

const RecentArticles = () => {
  const [trending, setTrending] = useState<any>(null);

  const sliderRef = useRef<Slider | null>(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const [breakpoint, setBreakpoint] = useState("");

  const handleGetMostRecent = async () => {
    try {
      const { data } = await API.get(
        `${BASE_API}/api/trending-videos/?populate=*`
      );
      setTrending(data);
      return data;
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const checkBreakpoint = () => {
      if (window.matchMedia("(max-width: 640px)").matches) {
        setBreakpoint("sm");
      } else setBreakpoint("md");
    };

    checkBreakpoint();
    handleGetMostRecent();
    window.addEventListener("resize", checkBreakpoint);

    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: breakpoint === "sm" ? 1 : 3,
    slidesToScroll: breakpoint === "sm" ? 1 : 3,
    autoplay: true,
    autoplaySpeed: 6000,
    rows: 1,
  };

  return (
    trending && (
      <div className="recent-articles pt-8 pb-8 my-10">
        <div className="container">
          <div className="recent-wrapper">
            {/* <!-- section Tittle --> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="section-tittle mb-30">
                  <h3>Video nổi bật</h3>
                  <div className="slider-btn pr-3">
                    <span className="slick-prev-slick-arrow" onClick={previous}>
                      <i className="fa fa-angle-left"></i>
                    </span>
                    <span className="slick-prev-slick-arrow" onClick={next}>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="slider-container">
                  <Slider {...settings} ref={sliderRef}>
                    {trending.data.map((e: any, i: number) => (
                      <div className="single-recent px-2 pr-4" key={i}>
                        <div className="what-img">
                          <Image
                            src={
                              BASE_API +
                              e.attributes.thumbnail.data.attributes.url
                            }
                            alt={e.attributes.title}
                            className="mx-auto"
                            // objectFit="cover"
                            width={100}
                            height={100}
                            sizes="100vw"
                            style={{
                              width: "100%",
                              height: breakpoint === "md" ? "165px" : "auto",
                            }}
                          />
                        </div>
                        <div className="what-cap">
                          <h4>
                            <Link
                              href={e.attributes.link}
                              passHref
                              legacyBehavior
                            >
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                // className="info1"
                              >
                                {e.attributes.title}
                              </a>
                            </Link>
                          </h4>
                          <Link
                            href={e.attributes.link}
                            passHref
                            legacyBehavior
                          >
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              // className="info1"
                            >
                              <span className="flaticon-play-button"></span>
                            </a>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default memo(RecentArticles);
