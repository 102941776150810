"use client";
import { BASE_API } from "@/config/site";
import dayjs from "dayjs";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

interface PWeekly3News {
  dataPost: any;
}

const Weekly3News = ({ dataPost }: PWeekly3News) => {
  const [breakpoint, setBreakpoint] = useState("");

  useEffect(() => {
    const checkBreakpoint = () => {
      if (window.matchMedia("(max-width: 640px)").matches) {
        setBreakpoint("sm");
      } else if (window.matchMedia("(max-width: 992px)").matches) {
        setBreakpoint("md");
      } else setBreakpoint("lg");
    };

    checkBreakpoint();
    window.addEventListener("resize", checkBreakpoint);

    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: breakpoint === "sm" ? 1 : breakpoint === "md" ? 3 : 4,
    slidesToScroll: breakpoint === "sm" ? 1 : breakpoint === "md" ? 3 : 4,
    autoplay: true,
    autoplaySpeed: 5000,
    rows: 1,
  };

  return (
    <div className="weekly3-news-area pt-[80px] pb-[130px]">
      <div className="container">
        <div className="weekly3-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <Slider {...settings}>
                {dataPost &&
                  dataPost.data.map((e: any, i: number) => (
                    <div className="weekly3-single px-2 pr-4" key={i}>
                      <div className="weekly3-img">
                        <Image
                          src={
                            BASE_API +
                            e.attributes.thumbnail.data.attributes.url
                          }
                          alt={e.attributes.title}
                          className="mx-auto"
                          // objectFit="cover"
                          width={100}
                          height={100}
                          sizes="100vw"
                          style={{
                            width: "100%",
                            borderRadius: "10px",
                            height: breakpoint === "sm" ? "auto" : "135px",
                          }}
                        />
                      </div>
                      <div className="weekly3-caption">
                        <h4>
                          <Link href={`/news/${e.attributes.slug}`} >
                            {e.attributes.title}
                          </Link>
                        </h4>
                        <p>
                          {dayjs(e.attributes.created_date).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Weekly3News;
